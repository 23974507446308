@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PolySans";
  src: local("PolySans"), url("./fonts/PolySans Neutral.ttf") format("truetype");
}

@font-face {
  font-family: "NewYork";
  src: local("NewYork"),
    url("./fonts/NewYork PERSONAL USE.otf") format("truetype");
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "sans-serif", "PolySans", "NewYork";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background-color: #0b0b0b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background: #0b0b0b;
  padding-top: 8px;
}

.navbar img {
  display: flex;
  align-items: flex-start;
  gap: 176.898px;
  box-sizing: border-box;
}

/*.navbar a {*/
/*    color: #FFF;*/
/*    font-family: PolySans;*/
/*    font-size: 17.69px;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    line-height: normal;*/
/*}*/

.navbar p {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 17.69px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar .menu {
  align-items: flex-end;
  width: 27.518px;
  height: 27.518px;
}
.navbar .items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar .items p:hover {
  cursor: pointer;
}

.navbar .items p {
  min-width: 100px;
  text-align: right;
}
.video1 {
  /*display: block;*/
  /*width: 100%;*/
  /*height: 628px;*/
  /*padding: 269.278px 0px 330.221px 0px;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*white-space: nowrap;*/
  /*position: relative;*/
  /*top: 50px;*/
  /*z-index: -1;*/
  /*background: #0B0B0B;*/
  display: flex;
  width: 100%;
  height: 100%;
  /*height: 628px !important;*/
  /*padding: 2% 0 2% 0;*/
  justify-content: center;
  align-items: center;
  object-fit: fill !important;
}

.App .projects h2 {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.headings:hover {
  color: red;
}

.arrow {
  display: inline;
  width: 29.483px;
  height: 29.483px;
  /*transform: rotate(-30deg);*/
  justify-content: center;
  align-items: center;
}

.quote {
  color: #fff;
  text-align: center;
  font-family: NewYork, serif;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-right: 16%;
  margin-left: 16%;
}

.App {
  /*width: 100%;*/
  /*height: 100%;*/
  background: #0b0b0b;
}

.Services {
  display: block;
  padding-bottom: 50px;
}

.Services h3 {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 38.328px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.serviceIcons {
  width: 120px !important;
  height: 110px !important;
}
.serviceIcons2 {
  width: 190px !important;
  height: 110px !important;
}
.hover-show {
  display: none !important;
}
.hover-img-div:hover > .hover-show {
  display: flex !important;
}
.hover-img-div:hover > .default-show {
  display: none !important;
}

.newyork {
  font-family: NewYork, serif;
}

.About {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.About h3 {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 3rem;
}

.About p {
  color: #fff;
  text-align: justify;
  font-family: NewYork, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 5rem;
  max-width: 70%;
}

.About video {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background: /*url(<path-to-image>)*/ lightgray 0 -295.258px / 100% 309.449% no-repeat;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  object-fit: fill;
}

.Contact h3 {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
}

.Contact p {
  color: #fff;
  text-align: justify;
  font-family: NewYork, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 639px;
  margin-bottom: 2.7rem;
}

.Contact a {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-right: 10%;
}

.Contact div {
  /*padding-bottom: 290px;*/
}

.forflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  width: 100%;
}

.App .more {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.logoandlinks {
  display: flex;
  padding-top: 10%;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10%;
}

.logoandlinks .logo {
  width: 191.8px;
  height: 87.466px;
  box-sizing: border-box;
}

.logoandlinks .marker {
  width: 108px;
  height: 138px;
  flex-shrink: 0;
}

.logoandlinks a {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 26.102px */
  text-decoration: none;
  padding-right: 2em;
  position: relative;
  top: 50px;
}

.copyright p {
  color: #a3a3a3;
  font-family: PolySans, serif;
  font-size: 15.724px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 26.102px */
}

.copyright img {
  display: flex;
  width: 23.586px;
  height: 23.586px;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.copyright {
  display: flex;
  justify-content: center;
  position: relative;
  right: 55px;
  margin-bottom: 1%;
}

/*module.exports = {*/
/*  corePlugins: {*/
/*    preflight: false,*/
/*  }*/
/*}*/

/*.projects .link{*/
/*  color: #A90D06;*/
/*  font-family: NewYork, serif;*/
/*  font-size: 38.328px;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  line-height: normal;*/
/*  text-transform: uppercase;*/
/*  text-decoration: none*/
/*}*/

.allimages {
  display: flex;
  padding: 0px 60px;
  gap: 60px;
  width: 100%;
}

.hoverlinkclass {
  width: 100%;
}

.seemore {
  color: #fff;
  font-family: PolySans, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.imgt {
  width: 100%;
  color: #fff;
  font-family: NewYork, serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-wrap: nowrap;
  line-height: normal;
  bottom: 6%;
  right: 0;
  /* transform: rotate(-15deg); */
}

.img1div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  /* left: 20%; */
  transform: rotate(-15deg);
  top: 0px;
}

.img2div {
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: -1;
  position: relative;
  transform: rotate(15deg);
  /* right: 55px; */
}

.img3div {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  right: 90px;
  bottom: 11px;
  /*align-items: center;*/
  /* transform: rotate(15deg) */
}

.img4div {
  /*width: 390.159px;*/
  /*height: 440.28px;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* right: 72px; */
  top: 0px;
  /*align-items: center;*/
  transform: rotate(15deg);
}

.img5div {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* right: 70px; */
  /*align-items: center;*/
  transform: rotate(-15deg);
}

.img6div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 0;
  /* transform: rotate(-15deg); */
  /* left: 20px */
}

.setimg {
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 5%;
  margin-top: 2%;
}

@media (max-width: 768px) {
  .serviceIcons {
    width: 80px !important;
    height: auto !important;
  }
  .serviceIcons2 {
    width: 120px !important;
    height: auto !important;
  }
}

@media (max-width: 767px) {
  .video1 {
    height: auto;
  }

  .projects {
    gap: 0;
  }

  .App .projects h2 {
    font-size: 24px;
  }

  .App .more {
    font-size: 15px;
    padding-bottom: 2rem;
  }

  .App .quote {
    font-size: 30px;
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .About {
    padding-top: 2rem;
  }

  .About h3 {
    font-size: 25px;
  }

  .About p {
    font-size: 18px;
    max-width: 100%;
  }

  .seemore {
    font-size: 18px;
  }

  .Contact h3 {
    font-size: 25px;
  }

  .Contact p {
    font-size: 20px;
  }

  .Contact a {
    font-size: 20px;
    width: 100%;
    display: flex;
  }

  .marker {
    display: none;
  }

  .logoandlinks {
    display: block;
  }

  .logoandlinks .logo {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
  }

  .links {
    display: flex;
    margin-bottom: 8%;
    justify-content: center;
  }

  .copyright {
    display: none;
  }

  .Services h3 {
    font-size: 25px;
    padding-bottom: 1rem;
  }

  .img4div {
    position: static;
    top: 0;
  }

  .setimg {
    width: 90% !important;
    border-radius: 20px;
    margin-bottom: 2.5%;
  }

  .img2div {
    position: static;
    margin-top: 0;
  }

  .img3div {
    position: static;
  }

  .horizontalscrollsection {
    height: 300vh;
  }
  .imgt {
    width: 90% !important;
    margin-bottom: 2%;
  }
  .Services {
    margin-bottom: 0;
  }
  .navbar .items {
    display: none;
  }
  .navbar img {
    justify-content: center;
  }
  .navbar {
    justify-content: center;
  }
}

/*.margin1 {*/
/*    margin: 0 5% 0 5%;*/
/*}*/

/*#A90D06*/
/*#0B0B0B*/
